import HomeD from '../assets/icons/home/home-d.svg'
import HomeL from '../assets/icons/home/home-l.svg'
import HomeU from '../assets/icons/home/home.svg'
import Pool from '../assets/icons/pools/pools.svg'
import PoolL from '../assets/icons/pools/pools-l.svg'
import PoolD from '../assets/icons/pools/pools-d.svg'
import Farm from '../assets/icons/farms/farm.svg'
import FarmL from '../assets/icons/farms/farm-l.svg'
import FarmD from '../assets/icons/farms/farm-d.svg'
import Gover from '../assets/icons/governance/gover.svg'
import GoverL from '../assets/icons/governance/gover-l.svg'
import GoverD from '../assets/icons/governance/gover-d.svg'
import Game from '../assets/icons/idogame/game.svg'
import GameL from '../assets/icons/idogame/game-l.svg'
import GameD from '../assets/icons/idogame/game-d.svg'

export const SideBarRouterLink = [
    {
        link: '/',
        lightIcon: HomeL,
        darkIcon: HomeD,
        unselectedIcon: HomeU,
        name: 'home'
    },
    // {
    //     link: '/pools',
    //     lightIcon: PoolL,
    //     darkIcon: PoolD,
    //     unselectedIcon: Pool,
    //     name: 'pools'
    // },
    {
        link: '/farms',
        lightIcon: FarmL,
        darkIcon: FarmD,
        unselectedIcon: Farm,
        name: 'DEX farms'
    },
    {
        link: '/staking',
        lightIcon: GoverL,
        darkIcon: GoverD,
        unselectedIcon: Gover,
        name: 'staking'
    },
    {
        link: '/ido-game',
        lightIcon: GameL,
        darkIcon: GameD,
        unselectedIcon: Game,
        name: 'IDO Game'
    },
]

export const MenuLink=[
    {
        href: '/',
        icon: 'home',
        iconActive: 'home_active',
        isActive: false,
        key: 0,
        name: 'Home'
    },
    {
        href: '/pools',
        icon: 'pools',
        iconActive: 'pools_active',
        isActive: false,
        key: 1,
        name: 'Pools'
    },
    {
        href: '/farms',
        icon: 'farms',
        iconActive: 'farms_active',
        isActive: false,
        key: 2,
        name: 'DEX Farm'
    },
    {
        href: '/staking',
        icon: 'staking',
        iconActive: 'staking_active',
        isActive: false,
        key: 3,
        name: 'Staking'
    },
    {
        href: 'https://www.housebets.io/',
        icon: 'ido',
        iconActive: 'ido_active',
        isActive: false,
        key: 4,
        name: 'HouseBets'
    },
    
]
