import { useParams } from "react-router";
import {
  LayoutTwoColumnTabs,
  FormAmount,
  PoolFormHeader,
  NavigationBreadcrumbs,
  Grid,
  FinancePeriodsChart,
  PoolHeader,
  FinanceTabValue,
  FinanceTabChart,
  FinanceStats,
  FinanceStatsChart,
} from "lunafi-ui";
import { useSelector, useDispatch } from "react-redux";
import { networks } from "../../config/farmsConfig";
import { useState, useEffect } from "react";
import _ from "lodash";
import { submitTransaction, _getGlobalValues, _getVolume } from "../../store";
import {
  POOL_APPROVE,
  POOL_INVEST,
  POOL_WITHDRAW,
  POOL_WITHDRAW_MAX,
} from "../../store/pools/actionTypes";
import { getChainIds } from "../../config/walletConfig";
import { IProps as IFinancePeriodsChartProps } from "lunafi-ui/dist/components/composed/finance/periods-chart/types";

export function PoolDetails() {
  const dispatch = useDispatch();
  const { ticker } = useParams();

  const globalPoolData = useSelector(
    (state: any) => state.Pools.globalValues.globalValues
  );
  const transactions = useSelector((state: any) => state.Pools.transactions);
  // lets keep token and dollar values in a separate objects for readability
  const tokenAmounts = globalPoolData[ticker!];
  const dollarValues = globalPoolData[ticker!].Dollar;
  const {
    graphTVLData,
    graphMEData,
    graphPNL7DaysData,
    graphPNL30DaysData,
    graphLiquidityData,
    graphPendingStakesData,
  } = useSelector((state: any) => state.Pools.graph);
  const connected = useSelector((state: any) => state.Wallet.connected);
  const poolInfo = useSelector(
    (state: any) => state.Pools.poolInfo[getChainIds().polygonChainId][ticker!]
  );
  const volumeData = useSelector((state: any) => state.Pools.volume[ticker!]);
  const tokenUSDPrice = tokenAmounts?.TokenPrice;

  const [inputAmount, setInputAmount] = useState(0);
  const [netWithdrawalUSDAmount, setNetWithdrawalUSDAmount] = useState(0);
  const [userShare, setUserShare] = useState(0);
  const [temperaryDepositShare, setTemperaryDepositShare] = useState(0);
  const [lostShare, setLostShare] = useState(0);
  const [remainingShare, setRemainingShare] = useState(0);
  const [allowance, setAllowance] = useState(false);
  const [depositStatus, setDepositStatus] = useState<
    "success" | "confirm" | "pending" | "default"
  >("default");
  const [withdrawStatus, setWithdrawStatus] = useState<
    "success" | "confirm" | "pending" | "default"
  >("default");

  const [refresh, setRefresh] = useState(false);
  const [myDeposit, setMyDeposit] = useState(0);

  useEffect(() => {
    if (refresh) {
      dispatch(_getGlobalValues({ refresh: true }));
      dispatch(_getVolume());
      setRefresh(false);
    }
  }, [refresh]);

  const [unstakeTransactionFailed, setUnstakeTransactionFailed] =
    useState(false);
  const withdrawConfirm = {
    continueName: "Confirm Withdrawal",
    description: (
      <>
      Please check Expected Value Lost carefully. If the figure is high, It's advised to return later once more bets have settled. Learn More <a href="https://docs.lunafi.io/lunafi/faqs-tutorials/house-pools">Learn More</a>
      </>
    ),
    onCancel: () => {
      setWithdrawStatus("default");
    },
    onContinue: () => {
      setWithdrawStatus("pending");
      onsubmit("withdraw", inputAmount);
    },
    title: "Warning",
  };
  const unstakeFailedConfirm = {
    continueName: "Please try again",
    description: <> </>,
    onCancel: () => {
      setWithdrawStatus("default");
      setUnstakeTransactionFailed(false);
    },
    onContinue: () => {
      setWithdrawStatus("default");
      setUnstakeTransactionFailed(false);
    },
    title: "Transaction Failed",
  };

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      if (transactions[transactions.length - 1].callStatus === "COMPLETED") {
        console.log("catch COMPLETED");
        if (depositStatus === "pending") {
          setDepositStatus("success");
          setRefresh(true);
        }
        if (withdrawStatus === "pending") {
          setWithdrawStatus("success");
          setRefresh(true);
        }
      }
      if (transactions[transactions.length - 1].callStatus === "FAILED") {
        console.log("catch FAILED");
        if (depositStatus === "pending") {
          setDepositStatus("confirm");
        }
        if (withdrawStatus === "pending") {
          setUnstakeTransactionFailed(true);
          setWithdrawStatus("confirm");
        }
      }
    }
  }, [
    transactions,
    transactions.length,
    transactions.length > 0 && transactions[transactions.length - 1].callStatus,
  ]);

  useEffect(() => {
    if (poolInfo) {
      if (poolInfo.totalSupply === 0) {
        setUserShare(0);
      } else {
        setUserShare(poolInfo.myLPTokenAmount / poolInfo.totalSupply);
        setMyDeposit(Number(poolInfo?.myLPTokenAmount*tokenAmounts?.LPPrice) || 0)
      }
    }
  }, [poolInfo, connected]);
  useEffect(() => {
    if (poolInfo) {
      if (poolInfo.totalSupply === 0) {
        setTemperaryDepositShare(0);
        setLostShare(0);
        setRemainingShare(0);
      } else {
        const tempLP = inputAmount / Number(tokenAmounts.LPPrice);
        setTemperaryDepositShare(
          (poolInfo.myLPTokenAmount + tempLP) / (poolInfo.totalSupply + tempLP)
        );
        setLostShare(tempLP / poolInfo.totalSupply);
        setRemainingShare(
          (poolInfo.myLPTokenAmount - tempLP) / poolInfo.totalSupply
        );
        const adjustedLiquidity = tokenAmounts?.Liquidity -
          Number(tokenAmounts?.ME) -
          Number(tokenAmounts?.PendingStakes)
        const effectiveMaxWithdrawl = poolInfo ? Math.min(adjustedLiquidity, poolInfo?.maxWithdrawl): 0;
        const netWithdrawalUSDAmount = poolInfo ?
        ((inputAmount < effectiveMaxWithdrawl)
                        ? inputAmount * tokenUSDPrice
                        : effectiveMaxWithdrawl * tokenUSDPrice)
                        : 0;
          setNetWithdrawalUSDAmount(netWithdrawalUSDAmount);
      }
    }
  }, [inputAmount]);

  const chart_color_yellow: IFinancePeriodsChartProps["chartProps"] = {
    axis: { x: false, y: false },
    grid: { x: true, y: false },
    bg: {
      colorEnd: "#F2B743",
      colorEndAlpha: 0,
      colorStart: "#F2B743",
      colorStartAlpha: 0,
    },
    line: {
      colorEnd: "#F2B743",
      colorEndAlpha: 1,
      colorStart: "#F2B743",
      colorStartAlpha: 1,
    },
  };

  const chart_color_pink: IFinancePeriodsChartProps["chartProps"] = {
    bg: {
      colorEnd: "#F2B743",
      colorEndAlpha: 0,
      colorStart: "#F2B743",
      colorStartAlpha: 0,
    },
    dot: { fill: "#FFFFFF", r: 6, stroke: "#6C0959", strokeWidth: 3 },
    line: {
      colorEnd: "#2D27FF",
      colorEndAlpha: 1,
      colorStart: "#FF0A6C",
      colorStartAlpha: 1,
    },
  };

  const OverviewGraphChildren = (
    <Grid container spacing={4}>
      <Grid item>
        <FinancePeriodsChart
          numberFormat={{ prefix: "$" }}
          periods={[
            {
              data: graphTVLData[ticker!].Day,
              key: "day",
              name: "1D",
              value: Number(dollarValues.TVL),
            },
            {
              data: graphTVLData[ticker!].Week,
              key: "week",
              name: "1W",
              value: Number(dollarValues.TVL),
            },
            {
              data: graphTVLData[ticker!].Month,
              key: "month",
              name: "1M",
              value: Number(dollarValues.TVL),
            },
            {
              data: graphTVLData[ticker!].Year,
              key: "year",
              name: "1Y",
              value: Number(dollarValues.TVL),
            },
          ]}
          title="TVL"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabValue
          info="My Current Deposit(Tokens)"
          numberFormat={{ prefix: "", rightFixed: 4 }}
          tabs={[
            { key: "Tokens", name: "Tokens", value: connected ? myDeposit: 0 },
          ]}
          title="My Deposit"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabValue
          info="My Current Deposit(USD)"
          numberFormat={{ prefix: "$", rightFixed: 2 }}
          tabs={[
            { key: "USD", name: "USD", value: connected ? myDeposit * tokenUSDPrice : 0 }
          ]}
          title="My Deposit"
        />
      </Grid>
      <Grid item size={6} xs={12}>
         <FinanceTabValue
           info="The expected house P&L on unsettled bets"
           numberFormat={{ prefix: "$" }}
           tabs={[
             { key: "overall", name: "Overall", value: Number(dollarValues?.EV) },
             {
               key: "yours",
               name: "Yours",
               value: connected ?
                 Math.round(Number(dollarValues?.EV) * userShare * 1000) / 1000: 0
             },
           ]}
           title="Expected Value"
         />
       </Grid>
       <Grid item size={6} xs={12}>
        <FinanceTabValue
          info="The amount the pool stands to lose in the worse case scenario"
          numberFormat={{ prefix: "$" }}
          tabs={[
            {
              key: "overall",
              name: "Overall",
              value: Number(dollarValues?.ME),
            },
            {
              key: "yours",
              name: "Yours",
              value: connected ?
              Math.round(
                Number(dollarValues?.ME) * userShare * 1000
                ) / 1000 : 0,
              },
            ]}
            title="Max Exposure"
          />
        </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabChart
          chartProps={chart_color_yellow}
          info="Returns from settled bets"
          numberFormat={{ suffix: "%" }}
          tabs={[
            {
              data: graphPNL30DaysData[ticker!],
              key: "30 Days",
              name: "30 Days",
              value: Number(tokenAmounts.Earnings30Day),
            },
            {
              data: graphPNL7DaysData[ticker!],
              key: "7 Days",
              name: "7 Days",
              value: Number(tokenAmounts.Earnings7Day),
            },
          ]}
          title="APY"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabChart
          chartProps={chart_color_yellow}
          info="Stakes from Housebets users on unsettled bets"
          numberFormat={{ prefix: "$" }}
          tabs={[
            {
              data: graphPendingStakesData[ticker!].Month,
              key: "30 Days",
              name: "30 Days",
              value: Number(dollarValues?.PendingStakes),
            },
            {
              data: graphPendingStakesData[ticker!].Week,
              key: "7 Days",
              name: "7 Days",
              value: Number(dollarValues?.PendingStakes),
            },
          ]}
          title="Pending Stakes"
        />
      </Grid>
    </Grid>
  );

  const AnalyticsGraphChildren = (
    <Grid container spacing={4}>
      <Grid item size={12}>
        <FinanceStats
          items={[
            { name: "Total", value: volumeData.total },
            { name: "24h volume", value: volumeData["24hour"] },
          ]}
          numberFormat={{ prefix: "$", rightFixed: 2 }}
        />
      </Grid>
      <Grid item size={12}>
        <FinanceStatsChart
          data={volumeData.volume}
          numberFormat={{ prefix: "$" }}
          title="Betting Volume"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabChart
          info="Total Value Locked = Liquidity + EV - Pending Stakes"
          chartProps={chart_color_pink}
          numberFormat={{ prefix: "$" }}
          tabs={[
            {
              data: graphTVLData[ticker!].Month,
              key: "30 Days",
              name: "30 Days",
              value: Number(dollarValues.TVL),
            },
            {
              data: graphTVLData[ticker!].Week,
              key: "7 Days",
              name: "7 Days",
              value: Number(dollarValues.TVL),
            },
          ]}
          title="TVL"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabChart
          chartProps={chart_color_yellow}
          info="Stakes from Housebets users on unsettled bets"
          numberFormat={{ prefix: "$" }}
          tabs={[
            {
              data: graphPendingStakesData[ticker!].Month,
              key: "30 Days",
              name: "30 Days",
              value: Number(dollarValues.PendingStakes),
            },
            {
              data: graphPendingStakesData[ticker!].Week,
              key: "7 Days",
              name: "7 Days",
              value: Number(dollarValues.PendingStakes),
            },
          ]}
          title="Pending Stakes"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabChart
          chartProps={chart_color_yellow}
          info="The amount the pool stands to lose in the worse case scenario"
          numberFormat={{ prefix: "$" }}
          tabs={[
            {
              data: graphMEData[ticker!].Month,
              key: "30 Days",
              name: "30 Days",
              value: Number(dollarValues.ME),
            },
            {
              data: graphMEData[ticker!].Week,
              key: "7 Days",
              name: "7 Days",
              value: Number(dollarValues.ME),
            },
          ]}
          title="Max Exposure"
        />
      </Grid>
      <Grid item size={6} xs={12}>
        <FinanceTabChart
          chartProps={chart_color_pink}
          info="Net deposits + P&L of settled bets + pending stakes"
          numberFormat={{ prefix: "$" }}
          tabs={[
            {
              data: graphLiquidityData[ticker!].Month,
              key: "30 Days",
              name: "30 Days",
              value: Number(dollarValues.Liquidity),
            },
            {
              data: graphLiquidityData[ticker!].Week,
              key: "7 Days",
              name: "7 Days",
              value: Number(dollarValues.Liquidity),
            },
          ]}
          title="Liquidity"
        />
      </Grid>
    </Grid>
  );

  const onsubmit = (actionType: string, amount?: number) => {
    if (actionType === "approve") {
      dispatch(
        submitTransaction({
          currentCall: POOL_APPROVE,
          poolType: ticker,
          chainId: getChainIds().polygonChainId,
          disableModal: true,
        })
      );
      setAllowance(true);
    } else if (actionType === "deposit") {
      dispatch(
        submitTransaction({
          currentCall: POOL_INVEST,
          poolType: ticker,
          amount: amount ?? 0,
          chainId: getChainIds().polygonChainId,
          disableModal: true,
        })
      );
      setAllowance(false);
    } else if (actionType === "withdraw") {
      if (amount! >= poolInfo.maxWithdrawl) {
        dispatch(
          submitTransaction({
            currentCall: POOL_WITHDRAW_MAX,
            poolType: ticker,
            chainId: getChainIds().polygonChainId,
            disableModal: true,
          })
        );
      } else {
        dispatch(
          submitTransaction({
            currentCall: POOL_WITHDRAW,
            poolType: ticker,
            amount: amount ?? 0,
            chainId: getChainIds().polygonChainId,
            disableModal: true,
          })
        );
      }
    }
  };

  return (
    <>
      <LayoutTwoColumnTabs
        asideTabs={[
          {
            children: (
              <FormAmount
                description={`${
                  Number(tokenAmounts?.LPPrice)?.toFixed(5) ?? 0
                } ${ticker} = 1 LF${ticker}LP`}
                header={
                  <PoolFormHeader
                    apy={{
                      lfiValue: Math.round(tokenAmounts.APR * 100) / 100,
                      value: Math.round(tokenAmounts.Earnings7Day * 100) / 100,
                    }}
                    img={`/img/coin/${ticker?.toLowerCase()}.svg`}
                    name={ticker ?? ""}
                    network={networks.matic}
                  />
                }
                info={[
                  {
                    name: "Liquidity",
                    tooltip:
                      "Net deposits + P&L of settled bets + pending stakes",
                    value:
                      connected ? Number(dollarValues.Liquidity) * userShare + inputAmount * tokenUSDPrice: 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                  {
                    name: "Expected Value",
                    tooltip: "The expected house P&L on unsettled bets",
                    value: connected ? Number(dollarValues.EV) * temperaryDepositShare : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: Number(tokenAmounts.EV) >= 0 ? "green" : "red",
                  },
                  {
                    name: "Pending Stakes",
                    tooltip: "Stakes from Housebets users on unsettled bets",
                    value: connected ?
                      Number(dollarValues.PendingStakes) *
                      temperaryDepositShare
                      : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "red",
                  },
                  {
                    name: "Max Exposure",
                    tooltip:
                      "The amount the pool stands to lose in the worse case scenario",
                    value: connected ? Number(dollarValues.ME) * temperaryDepositShare : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                  },
                  {
                    name: "Total Value Locked",
                    tooltip: "Liquidity + EV - Pending Stakes",
                    value: connected ?
                      Number(myDeposit * tokenUSDPrice)
                      : 0,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                ]}
                input={
                  poolInfo.allowance
                    ? {
                        icon: `/img/coin/${ticker?.toLowerCase()}.svg`,
                        label: "Amount to deposit",
                        max: poolInfo?.walletBalance ?? 0,
                        min: 0,
                        onChange: (value: any) => {
                          if (value) {
                            setInputAmount(value);
                          } else {
                            setInputAmount(0);
                          }
                        },
                        value: inputAmount,
                      }
                    : undefined
                }
                status={depositStatus}
                submit={{
                  isDisabled:
                    !connected ||
                    (poolInfo.allowance !== 0 &&
                      (inputAmount <= 0 ||
                        inputAmount > poolInfo?.walletBalance)),
                  name: connected
                    ? poolInfo
                      ? poolInfo.allowance === 0
                        ? "Approve"
                        : "Deposit"
                      : "Loading"
                    : "Connect Wallet",
                  onSubmit: () => {
                    !poolInfo.allowance
                      ? onsubmit("approve")
                      : onsubmit("deposit", inputAmount);
                    setDepositStatus("pending");
                  },
                }}
                confirm={{
                  continueName: "Please try Again",
                  description: <> </>,
                  onCancel: () => setDepositStatus("default"),
                  onContinue: () => setDepositStatus("default"),
                  title: "Transaction Failed",
                }}
                success={{
                  additionalText: (
                    <span>
                      Your Balance:{" "}
                      <b>
                        {poolInfo?.walletBalance} {ticker}
                      </b>
                    </span>
                  ),
                  amount: allowance
                    ? undefined
                    : {
                        numberFormat: {
                          prefix: ` ${ticker}`,
                          rightFixed: 2,
                        },
                        value: inputAmount,
                      },
                  description: allowance
                    ? "Your approval has been processed."
                    : "Your deposit has been processed.",
                  onDoneClick: () => {
                    setDepositStatus("default");
                  },
                  title: "Success!",
                }}
              />
            ),
            key: 3,
            name: "Deposit",
          },
          {
            children: (
              <FormAmount
                description={`${
                  Number(tokenAmounts?.LPPrice)?.toFixed(5) ?? 0
                } ${ticker} = 1 LF${ticker}LP`}
                header={
                  <PoolFormHeader
                    apy={{
                      lfiValue: Math.round(tokenAmounts.APR * 100) / 100,
                      value: Math.round(tokenAmounts.Earnings7Day * 100) / 100,
                    }}
                    img={`/img/coin/${ticker?.toLowerCase()}.svg`}
                    name={ticker ?? ""}
                    network={networks.matic}
                  />
                }
                info={[
                  {
                    name: "Adjusted Liquidity",
                    tooltip:
                      "Liquidity that can be withdrawn less max exposure & pending stakes",
                    value:
                      dollarValues.Liquidity -
                      Number(dollarValues.ME) -
                      Number(dollarValues.PendingStakes),
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                  {
                    name: "Remaining TVL",
                    tooltip: "Your TVL left after entered withdrawal",
                    value: dollarValues.TVL * remainingShare < 0 ? 0 : dollarValues.TVL * remainingShare,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "green",
                  },
                  {
                    name: "Expected Value Lost",
                    tooltip:
                      "You lose any positive EV relative to your deposit EV when exiting the pool",
                    value: Number(dollarValues.EV) * lostShare,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                    color: "red",
                  },
                  {
                    name: "Net Withdrawal Amount",
                    value: netWithdrawalUSDAmount,
                    numberFormat: { prefix: "$", rightFixed: 2 },
                  },
                ]}
                input={{
                  icon: `/img/coin/${ticker?.toLowerCase()}.svg`,
                  label: "Amount to withdraw",
                  max: poolInfo ? poolInfo.maxWithdrawl : 0,
                  min: 0,
                  onChange: (value: any) => {
                    if (value) {
                      setInputAmount(value);
                    } else {
                      setInputAmount(0);
                    }
                  },
                  value: inputAmount,
                }}
                status={withdrawStatus}
                submit={{
                  isDisabled:
                    !connected ||
                    poolInfo.allowance <= 0 ||
                    inputAmount <= 0 ||
                    inputAmount > poolInfo?.maxWithdrawl,
                  name: connected ? "Withdraw" : "Connect Wallet",
                  onSubmit: () => {
                    if(Number(dollarValues?.EV)<=0){
                    setWithdrawStatus("pending");
                    onsubmit("withdraw", inputAmount);
                    }else{
                      setWithdrawStatus("confirm");
                    }
                  },
                }}
                confirm={
                  unstakeTransactionFailed
                    ? unstakeFailedConfirm
                    : withdrawConfirm
                }
                success={{
                  additionalText: (
                    <span>
                      Your Balance:{" "}
                      <b>
                        {poolInfo?.walletBalance} {ticker}
                      </b>
                    </span>
                  ),
                  amount: {
                    numberFormat: {
                      suffix: ` ${ticker}`,
                      rightFixed: 2,
                    },
                    value: inputAmount,
                  },
                  description: "Your withdraw has been processed.",
                  onDoneClick: () => {
                    setWithdrawStatus("default");
                  },
                  title: "Success!",
                }}
              />
            ),
            key: 4,
            name: "Withdraw",
          },
        ]}
        breadcrumbs={
          <NavigationBreadcrumbs
            items={[
              { href: "/pools/", name: "Pools" },
              { href: `/pools/${ticker}/pool`, name: `${ticker}` },
            ]}
          />
        }
        contentTabs={[
          {
            children: OverviewGraphChildren,
            key: 0,
            name: "Overview",
          },
          {
            children: AnalyticsGraphChildren,
            key: 1,
            name: "Analytics",
          },
        ]}
        header={
          <PoolHeader
            img={`/img/coin/${ticker!.toLowerCase()}.svg`}
            label={`LF${ticker}LP`}
            name={`${ticker}`}
          />
        }
      />
    </>
  );
}
