import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { _getGlobalValues, _getVolume, _getSportsbookFunds } from "../store/pools/actions";
import {
  BannerSlider,
  BannerSliderItem,
  BoxHeading,
  LayoutScrollView,
  TokenPreviewEarningAction,
  Grid,
  TokenLfiAnalytics,
  FinancePieChart,
  FinancePeriodsPriceChart,
  LayoutWrapper,
} from "lunafi-ui";
import poolConfig from "../config/poolsConfig";
import { networks } from "../config/farmsConfig";
import { IChartPieData } from "lunafi-ui/dist/components/internal/charts/pie/types";
import packageJson from "../../package.json";
console.log("Version: ", packageJson.version);

export default function Home(props: any) {
  ReactGA.pageview(window.location.pathname);
  const globalPoolData = useSelector(
    (state: any) => state?.Pools?.globalValues?.globalValues
  );

  const LFIPrice = useSelector(
    (state: any) => state?.Pools?.globalValues?.globalValues?.LFI?.TokenPrice
  );
  const LFIPriceChart = useSelector(
    (state: any) => state?.Governance?.LFIPriceData
  );
  const volume = useSelector((state: any) => state?.Pools?.volume);
  const sportsbookFunds = useSelector((state: any) => state?.Pools?.sportsbookFunds);
  const PieChartData: IChartPieData[] = Object.keys(poolConfig).map(
    (key: string, index: number) => ({
      hex: poolConfig[key].color,
      key: index.toString(),
      name: key,
      value:
        globalPoolData && globalPoolData[key]
          ? globalPoolData[key].Dollar.TVL
          : 0,
    })
  );
  var PieChartDataTotal = 0;
  PieChartData.forEach(
    (data: IChartPieData) => (PieChartDataTotal += data.value)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(_getGlobalValues());
    dispatch(_getVolume());
    dispatch(_getSportsbookFunds());
  }, []);
  return (
    <LayoutWrapper>
      <Grid container lg={10} md={10} sm={8} spacing={12} xs={6}>
        <Grid item>
          <BannerSlider>
            <BannerSliderItem.FAQ
              description="Be a part of the community"
              link={{
                href: "https://docs.lunafi.io/lunafi/faqs-tutorials/faq",
                name: "Go To FAQ’s",
              }}
              title={
                <>
                  Getting
                  <br />
                  Started
                </>
              }
            />
            <BannerSliderItem.Discord
              description="Be a part of the community"
              link={{
                href: "https://docs.lunafi.io/lunafi/",
                name: "Go to Discord",
              }}
            />
            <BannerSliderItem.Rewards
              description="Sign up now and start earning!"
              link={{ href: "https://www.housebets.io/", name: "Sign Up" }}
            />
          </BannerSlider>
        </Grid>
        <Grid item>
          <BoxHeading
            heading={
              <LayoutScrollView animation="alpha" delay={500}>
                <span>House Pools</span>
              </LayoutScrollView>
            }
          >
            <Grid container spacing={6}>
              {Object.keys(poolConfig).map((key: string) => (
                <Grid item lg={4} md={6} size={3} sm={6} xl={4} xs={12}>
                  <LayoutScrollView isAppearAnimation>
                    <TokenPreviewEarningAction
                      title="Estimated APR"
                      action={{
                        name: "Deposit",
                        href: key === "LFI" ? "/staking" : `/pools/${key}/pool`,
                      }}
                      earning={{
                        lfi: {
                          suffix: "% LFI",
                          hasSignPrefix: true,
                          value:
                            globalPoolData && globalPoolData[key]
                              ? Number(globalPoolData[key]?.APR)
                              : 0,
                        },
                        value: {
                          suffix: "%  ",
                          hasSignPrefix: true,
                          value:
                            globalPoolData && globalPoolData[key]
                              ? Number(globalPoolData[key]?.Earnings7Day)
                              : 0,
                        },
                      }}
                      hasHover
                      img={`/img/coin/${key.toLowerCase()}.svg`}
                      name={key}
                      network={networks.matic}
                      size="large"
                      value={
                        globalPoolData && globalPoolData[key]
                          ? globalPoolData[key].Dollar.Liquidity
                          : 0
                      }
                    />
                  </LayoutScrollView>
                </Grid>
              ))}
            </Grid>
          </BoxHeading>
        </Grid>




        <Grid item>
          <BoxHeading
            heading={
              <LayoutScrollView animation="alpha" delay={500}>
                <span>Housebets Deposits</span>
              </LayoutScrollView>
            }
          >
            <Grid container spacing={6}>
              {Object.keys(poolConfig).map((key: string) => (
                <Grid item lg={4} md={6} size={3} sm={6} xl={4} xs={12}>
                  <LayoutScrollView isAppearAnimation>
                  <TokenLfiAnalytics
                        network={networks.matic}
                        // percent={globalPoolData?.LFI?.Earnings7Day}
                        icon={React.createElement('img', {
                          src: `/img/coin/${key.toLowerCase()}.svg`,
                          alt: key
                        })}
                        title={key}
                        value={{
                          prefix: "$",
                          rightFixed: 2,
                          value: sportsbookFunds && sportsbookFunds[key]
                          ? sportsbookFunds[key]?.Dollar?.amount
                          : 0,
                        }}
                      />
                  </LayoutScrollView>
                </Grid>
              ))}
            </Grid>
          </BoxHeading>
        </Grid>

        
        <Grid item>
          <BoxHeading
            heading={
              <LayoutScrollView animation="alpha" delay={500}>
                <span>Analytics</span>
              </LayoutScrollView>
            }
          >
            <Grid container spacing={6} xxl={5}>
              <Grid item size={12} xxl={3}>
                <Grid container spacing={6} xxl={5}>
                  <Grid item size={6} xs={12} xxl={12}>
                    <LayoutScrollView isAppearAnimation>
                      <TokenLfiAnalytics
                        network={networks.matic}
                        title="LFI Staking"
                        percent={Number(globalPoolData?.LFI?.['24ChangePercent'])}
                        value={{
                          prefix: "$",
                          rightFixed: 2,
                          value: globalPoolData?.LFI?.Dollar?.TVL,
                        }}
                      />
                    </LayoutScrollView>
                  </Grid>
                  <Grid item size={6} xs={12} xxl={12}>
                    <LayoutScrollView isAppearAnimation>
                      <TokenLfiAnalytics
                        network={networks.matic}
                        title="Betting Volume"
                        percent={0.5}
                        value={{ prefix: "$", rightFixed: 0, value: volume?.total }}
                      />
                    </LayoutScrollView>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item size={12} xxl={9}>
                <LayoutScrollView isAppearAnimation>
                  <FinancePieChart
                    data={PieChartData}
                    date={new Date().toDateString()}
                    numberFormat={{ prefix: "$", rightFixed: 2 }}
                    title="Total Value Locked"
                    value={PieChartDataTotal}
                  />
                </LayoutScrollView>
              </Grid>
            </Grid>
          </BoxHeading>
        </Grid>
        <Grid item>
          <BoxHeading
            heading={
              <LayoutScrollView animation="alpha" delay={500}>
                <span>LFI Token Price</span>
              </LayoutScrollView>
            }
          >
            <LayoutScrollView isAppearAnimation>
              <FinancePeriodsPriceChart
                numberFormat={{ prefix: "$", rightFixed: 4 }}
                periods={[
                  {
                    data:
                      LFIPriceChart && LFIPriceChart.day
                        ? LFIPriceChart.day
                        : [],
                    key: "day",
                    name: "1D",
                    percent:
                      LFIPriceChart &&
                      LFIPriceChart.day &&
                      LFIPriceChart.day.length > 0
                        ? LFIPriceChart.day[LFIPriceChart.day.length - 1]
                            .percent
                        : 0,
                    value: LFIPrice,
                  },
                  {
                    data:
                      LFIPriceChart && LFIPriceChart.week
                        ? LFIPriceChart.week
                        : [],
                    key: "week",
                    name: "1W",
                    percent:
                      LFIPriceChart && LFIPriceChart.week.length > 0
                        ? LFIPriceChart.week[LFIPriceChart.week.length - 1]
                            .percent
                        : 0,
                    value: LFIPrice,
                  },
                  {
                    data:
                      LFIPriceChart && LFIPriceChart.month
                        ? LFIPriceChart.month
                        : [],
                    key: "month",
                    name: "1M",
                    percent:
                      LFIPriceChart && LFIPriceChart.month.length > 0
                        ? LFIPriceChart.month[LFIPriceChart.month.length - 1]
                            .percent
                        : 0,
                    value: LFIPrice,
                  },
                  {
                    data:
                      LFIPriceChart && LFIPriceChart.year
                        ? LFIPriceChart.year
                        : [],
                    key: "year",
                    name: "1Y",
                    percent:
                      LFIPriceChart && LFIPriceChart.year.length > 0
                        ? LFIPriceChart.year[LFIPriceChart.year.length - 1]
                            .percent
                        : 0,
                    value: LFIPrice,
                  },
                ]}
                title="LFI Token"
              />
            </LayoutScrollView>
          </BoxHeading>
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
