import ReactGA from "react-ga";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { networks } from "../config/farmsConfig";
import _ from "lodash";
import {
  Grid,
  LayoutWrapper,
  ConnectSubmit,
  BannerMedia,
  PoolsListBox,
  FormSelect,
  PoolsList,
  BannerInfo,
  ButtonSimple,
  CoinBuy,
  TokenPreviewEarning,
  TokenPreviewClaim,
  Popup,
  TokenPreviewSlider,
} from "lunafi-ui";
import { POOL_HARVEST } from "../store/pools/actionTypes";
import { submitTransaction } from "../store/actions";
import { getChainIds } from "../config/walletConfig";
import poolConfig from "../config/poolsConfig";

export default function Pool() {
  ReactGA.pageview(window.location.pathname);

  const defaultList = Object.keys(poolConfig).map(
    (key: string, index: number) => ({
      apr: { lfiValue: 0, value: 0 },
      href: key === "LFI" ? "/staking" : ``,
      icon: `/img/coin/${key.toLowerCase()}.svg`,
      key: index,
      label: `LF${key}LP`,
      liquidity: 0,
      myLiquidity: 0,
      name: key,
      network: networks.matic,
      network_key: "matic",
    })
  );

  const globalPoolData = useSelector(
    (state: any) => state.Pools.globalValues.globalValues
  );
  const poolInfo = useSelector((state: any) => state.Pools.poolInfo);
  const { connected } = useSelector((state: any) => state.Wallet);
  const dispatch = useDispatch();

  const [stakeableAssets, setStakeableAssets] = useState<any[]>([]);
  const [claimableLFI, setClaimableLFI] = useState<any[]>([]);
  const [poolList, setPoolList] = useState<any[]>(defaultList);
  const [coinFilter, setCoinFilter] = useState<String[]>([
    "USDC",
    "WETH",
    "WBTC",
    "LFI",
  ]);
  const [networkFilter, setNetworkFilter] = useState<String[]>(["matic", "sx"]);

  useEffect(() => {
    if (poolInfo && poolInfo[getChainIds().polygonChainId]) {
      const stakeableList: any[] = [];
      const claimableList: any[] = [];
      Object.keys(poolInfo[getChainIds().polygonChainId]).forEach(
        (key, index) => {
          if (poolInfo[getChainIds().polygonChainId][key].walletBalance > 0) {
            const stakeableAsset = (
              <TokenPreviewEarning
                key={index}
                earning={{
                  value: {
                    suffix: "%",
                    value: globalPoolData
                      ? Math.round(globalPoolData[key].Earnings7Day * 100) / 100
                      : 0,
                  },
                  lfi: {
                    suffix: "% LFI",
                    value: globalPoolData
                      ? Math.round(globalPoolData[key].APR * 100) / 100
                      : 0,
                  },
                }}
                img={`/img/coin/${key.toLowerCase()}.svg`}
                name={key}
                network={networks.matic}
                size="small"
                href={key !== "LFI" ? `/pools/${key}/pool` : "/staking"}
              />
            );
            stakeableList.push(stakeableAsset);
          }
          if (poolInfo[getChainIds().polygonChainId][key].reward > 0) {
            const claimableAsset = (
              <TokenPreviewClaim
                img={`/img/coin/${key.toLowerCase()}.svg`}
                name={key}
                network={networks.matic}
                rewards={{
                  value: poolInfo[getChainIds().polygonChainId][key].reward,
                  suffix: "LFI",
                }}
                buttons={{
                  onClick() {
                    dispatch(
                      submitTransaction({
                        currentCall: POOL_HARVEST,
                        poolType: key,
                        amount: (
                          poolInfo[getChainIds().polygonChainId][key].reward ??
                          0
                        ).toString(),
                        chainId: String(getChainIds().polygonChainId),
                      })
                    );
                  },
                }}
              />
            );
            claimableList.push(claimableAsset);
          }
        }
      );
      setStakeableAssets(stakeableList);
      setClaimableLFI(claimableList);
    }
  }, [poolInfo, globalPoolData]);

  useEffect(() => {
    if (globalPoolData) {
      const list = Object.keys(globalPoolData).map(
        (key: string, index: number) => ({
          apy: {
            lfiValue: Math.round(globalPoolData[key].APR * 100) / 100,
            tooltip: (
              <>
                <mark>Estimated APR</mark>
                <p>
                  7 Days:{" "}
                  {Math.round(globalPoolData[key].Earnings7Day * 100) / 100}%{" "}
                  {key}{" "}
                  <i>
                    <b>
                      + {Math.round(globalPoolData[key].APR * 100) / 100}% LFI
                    </b>
                  </i>
                </p>
                <p>
                  30 Days:{" "}
                  {Math.round(globalPoolData[key].Earnings30Day * 100) / 100}%{" "}
                  {key}{" "}
                  <i>
                    <b>
                      + {Math.round(globalPoolData[key].APR * 100) / 100}% LFI
                    </b>
                  </i>
                </p>
              </>
            ),
            value: Math.round(globalPoolData[key].Earnings7Day * 100) / 100,
          },
          href: key === "LFI" ? "/staking" : `/pools/${key}/pool`,
          icon: `/img/coin/${key.toLowerCase()}.svg`,
          key: index,
          label: `LF${key}LP`,
          liquidity: globalPoolData[key].Dollar.Liquidity.toString(),
          myLiquidity: connected
            ? Math.round(
                poolInfo &&
                  poolInfo[getChainIds().polygonChainId] &&
                  poolInfo[getChainIds().polygonChainId][key]
                  ? poolInfo[getChainIds().polygonChainId][key].myLiquidity *
                      globalPoolData[key].TokenPrice
                  : 0
              ).toString()
            : undefined,
          name: key,
          network: networks.matic,
          network_key: "matic",
        })
      );
      {
        /* @ts-ignore */
      }
      setPoolList(list);
    }
  }, [poolInfo, globalPoolData]);

  return (
    <LayoutWrapper>
      <Grid container md={8} sm={7} spacing={9} xs={6}>
        <Grid item>
          <BannerMedia
            description="You can withdraw your LP tokens at anytime "
            header="Stake LP, earn rewards"
            href="https://docs.lunafi.io/lunafi/"
            media={{ img: "/img/general/banner.jpg" }}
          />
        </Grid>
        {!connected ? (
          <Grid item>
            <BannerInfo
              button={<ConnectSubmit text="Connect Wallet" />}
              text="Your wallet is not connected"
            />
          </Grid>
        ) : (
          <>
            {stakeableAssets.length === 0 ? (
              <Grid item></Grid>
            ) : (
              <Grid item>
                <TokenPreviewSlider
                  tabs={[
                    {
                      children:
                        stakeableAssets.length === 0
                          ? [
                              <BannerInfo
                                button={
                                  <Popup
                                    trigger={
                                      <ButtonSimple
                                        size="small"
                                        tag="button"
                                        variant="light"
                                      >
                                        Buy Now
                                      </ButtonSimple>
                                    }
                                  >
                                    <CoinBuy />
                                  </Popup>
                                }
                                text="No assets available to stake"
                              />,
                            ]
                          : stakeableAssets,
                      key: 0,
                      name: "Stakeable Assets",
                    },
                    {
                      children: claimableLFI,
                      key: 1,
                      name: `Claimable LFI ${
                        claimableLFI.length !== 0 ? "*" : ""
                      }`,
                    },
                  ]}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item>
          <PoolsListBox
            filters={
              <>
                <FormSelect
                  label="Network"
                  multiple
                  onChange={(list) => {
                    if (list.length === 0) {
                      setNetworkFilter(["matic", "sx"]);
                    } else {
                      setNetworkFilter(list);
                    }
                  }}
                  options={[networks.matic, networks.sx]}
                />
                <FormSelect
                  label="Tokens"
                  multiple
                  onChange={(list) => {
                    if (list.length === 0) {
                      setCoinFilter(["LFI", "WBTC", "WETH", "USDC"]);
                    } else {
                      setCoinFilter(list);
                    }
                  }}
                  options={[
                    { name: "LFI", value: "LFI" },
                    { name: "WBTC", value: "WBTC" },
                    { name: "WETH", value: "WETH" },
                    { name: "USDC", value: "USDC" },
                  ]}
                />
              </>
            }
            heading="All Pools"
          >
            <PoolsList
              items={poolList
                .filter((pool) => coinFilter.includes(pool.name))
                .filter((pool) => networkFilter.includes(pool.network_key))}
            />
          </PoolsListBox>
        </Grid>
      </Grid>
    </LayoutWrapper>
  );
}
