export const FooterConfig = {
    menu: [
        {
            links: [
                {
                    href: '/',
                    name: 'Home'
                },
                {
                    href: '/pools',
                    name: 'Pools'
                },
                {
                    href: '/farms',
                    name: 'Farms'
                },
                {
                    href: '/staking',
                    name: 'Staking'
                }
            ],
            name: 'Product'
        },
        {
            links: [
                {
                    href: 'https://t.me/LunaFi_Official',
                    isExternal: true,
                    name: 'Telegram'
                },
                {
                    href: 'https://twitter.com/LunaFi_Project',
                    isExternal: true,
                    name: 'Twitter'
                },
                {
                    href: 'https://discord.gg/j2wDzZCshq',
                    isExternal: true,
                    name: 'Discord'
                },
                {
                    href: 'https://www.linkedin.com/company/lunafi-project/',
                    isExternal: true,
                    name: 'LinkedIn'
                }
            ],
            name: 'Follow us'
        },
        {
            links: [
                {
                    href: 'https://docs.lunafi.io/lunafi/',
                    isExternal: true,
                    name: 'Docs'
                },
                {
                    href: 'https://lunafi.io/#home-faq',
                    isExternal: true,
                    name: 'FAQ\'s'
                }
            ],
            name: 'Support'
        }
    ],
    social: {
        discord: 'https://discord.gg/j2wDzZCshq',
        docs: 'https://docs.lunafi.io/',
        linkedin: 'https://www.linkedin.com/company/lunafi-project/',
        telegram: 'https://t.me/LunaFi_Official',
        twitter: 'https://twitter.com/LunaFi_Project'
    }
}