const poolConfigV2 = {
    80001: {
        LFIWETH: {
            name: "LFI/ETH LP",
            ticker: "LFIETHLP",
        },
        LFI: {
            name: "LFI",
            ticker: "LFI",
        },
        WBTC: {
            name: "Wrapped Bitcoin",
            ticker: "WBTC",
        },
        WETH: {
            name: "Wrapped Ethereum",
            ticker: "WETH",
        }

    },
    137: {
        LFI: {
            name: "LFI",
            ticker: "LFI",
        },
        LFIWETH: {
            name: "LFI/ETH LP",
            ticker: "LFIETHLP",
        },
        WBTC: {
            name: "Wrapped Bitcoin",
            ticker: "WBTC",
        },
        WETH: {
            name: "Wrapped Ethereum",
            ticker: "WETH",
        },

    },
    647: {
        WBTC: {
            name: "Wrapped Bitcoin",
            ticker: "WBTC",
        },
        WETH: {
            name: "Wrapped Ethereum",
            ticker: "WETH",
        },
        USDC: {
            name: "USDC",
            ticker: "USDC",
        },
        LFI: {
            name: "LFI",
            ticker: "LFI"
        }
    }
}

export default poolConfigV2